import React, { useState } from "react";

import EventCard from "./event-card/event-card";

import "./events-grid.scss";

let started = 0;

const EventsGrid = ({ events, length }) => {
  const [canShowMore, setCanShowMore] = useState(false);
  const [displayedEvents, setDisplayedEvents] = useState(6)

  /**
   * Used to decide if the show more button should show at the beginning
   */
  const handleDisplay = () => {
    started = 1;
    if (length > displayedEvents) {
      setCanShowMore(true);
    }
  };

  /**
   * Function to display more Parties and remove teh show more button once there are no more
   * Parties to display
   */
  const showMoreClickHandler = () => {
    setDisplayedEvents(displayedEvents + 6);
    if (displayedEvents >= length - 6 || displayedEvents + 6 >= events.length) {
      setCanShowMore(false)
    }
  };

  return (
    <div className="event-grid">
      <div className="event-grid__grid-container">
        {started == 0 && handleDisplay()}
        {events ? length !== 0 && (
          <>
            {events.slice(0, displayedEvents).map((event, index) => (
              <div key={index} className="event-grid__col">
                <EventCard data={event}/>
              </div>
            ))}
            {canShowMore && (
              <button
                className="btn event-grid__show-more-btn"
                onClick={showMoreClickHandler}
              >
                Show more
              </button>
            )}
          </>
        ) : (
          <><h2 className="event-grid__placeholder">Coming Soon</h2></>
        )}
      </div>
    </div>
  );
};

export default EventsGrid;
