import React from "react";
import AvatarList from "../../avatar-list/avatar-list";

import "./event-card.scss";

import { PartieLogo } from "../../../svgs/social";
import { TrackedLink } from "../..";

const EventCard = ({ data }) => {
  const {
    id,
    hostName,
    hostId,
    gameTitle,
    fileName,
    shortUrl,
    people,
    personality,
    prize,
    participantsCount,
    maxPlayers,
    time,
    displayName,
  } = data;

  /**
   * Uses the data to create a like to the in-app Partie &
   * also builds the image link for the host
   * 
   * Note: The display of users inside the partie is handled in the Avatar List component
   */
  const fileLink = `https://cdn.partie.com/rooms/${hostId}/${id}/${fileName}`;
  const avatarImg = `https://cdn.partie.com/images/${hostId}/avatar/avatar_160x160.jpg`;

  return (
    <div className="event-card">
      {hostName && (
        <div className="event-card__content">
          <div className="event-card__personality-container">
            <div className="event-card__personality-avatar">
              <img src={avatarImg} alt={hostName} className="avatar-img" loading="lazy" />
            </div>
            <div className="event-card__personality-details">
              <span className="event-card__personality-name">
                {hostName}
                {gameTitle && (
                  <>
                    <span className="event-card__triangle" />
                    {gameTitle}
                  </>
                )}
              </span>
              {personality?.lastUpdate && (
                <span className="event-card__last-update">
                  {personality.lastUpdate}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {fileName && (
        <img src={fileLink} alt={displayName} className="partie-img" loading="lazy" />
      )}
      {!fileName && (
        <PartieLogo alt={displayName} className="partie-img" loading="lazy" />
      )}
      <div className="event-card__content">
        <div className="event-card__row event-card__row--title">
          {displayName && <h3 className="event-card__title">{displayName}</h3>}
          {prize && <span className="event-card__prize">{prize}</span>}
        </div>

        <div className="event-card__row event-card__row--players">
          <AvatarList avatars={people} maxVisible={participantsCount} />
          <span className="event-card__players-count">
            {participantsCount || 0}/{maxPlayers || 'Any'} Signed
          </span>
        </div>

        <div className="event-card__row event-card__row--join">
          <span className="event-card__time">{time}</span>
          {shortUrl && (
            <TrackedLink type="btn btn--accent event-card__join-btn" to={shortUrl} children="Join" />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
